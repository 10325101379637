import { muiTheme as rebrandTheme } from '@tkww/the-bash-frontend';
import fonts from 'styles/theme/fonts';
import boxShadows from 'styles/theme/boxShadow';

export default {
  fonts,
  boxShadows,
  constants: { boxShadows },
  fontSizes: rebrandTheme.fontSizes,
  fontWeights: rebrandTheme.fontWeights,
  palette: rebrandTheme.palette,
};
