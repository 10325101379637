import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'styles/theme';
import Image from 'components/Image';
import { Button } from '@tkww/the-bash-frontend';

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: '362px',
  borderRadius: '0px 50px',
  border: `1px solid ${theme.palette.grey[400]}`,
  background: '#F9F9F9',
  padding: '40px 22px',
  margin: 'auto',
  textAlign: 'center',
}));

export const AccountFeature = ({
  header,
  title,
  description,
  imgUrl,
  linkText,
  linkPath,
  onClick,
}) => {
  return (
    <StyledBox>
      <Typography
        variant="h5"
        component="h2"
        sx={{ fontFamily: theme.fonts.regular, textTransform: 'uppercase', margin: 0 }}
      >
        {header}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontFamily: theme.fonts.regular,
          fontWeight: '800',
          margin: '8px 0 0',
        }}
      >
        {title}
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '16px', color: '#505050' }}>
        {description}
      </Typography>
      <Box display="flex" justifyContent="center" margin="12px 28px">
        <Image src={imgUrl} sx={{ height: '140px' }} quality={100} />
      </Box>
      <Button
        href={linkPath}
        type="secondary"
        onClick={() => onClick(linkText)}
        sx={{ width: '210px', textTransform: 'uppercase' }}
      >
        {linkText}
      </Button>
    </StyledBox>
  );
};
